"use client";
export const runtime = "edge";

import { useEffect } from "react";
import * as Sentry from "@sentry/nextjs";

export default function Error({
    error,
    reset
}: {
    error: Error & { digest?: string },
    reset: () => void
}) {
    useEffect(() => {
        // Log the error to Sentry
        Sentry.captureException(error);
        console.error(error);
    }, [error]);

    return (
        <div>
            <h1>I dunno, some error happened</h1>
            <h1>Yes this error page is not the prettiest..</h1>
            <h1>{error.message}</h1>
            <p>{JSON.stringify(error)}</p>
            <button onClick={() => reset()}>Try again</button>
        </div>
    )
}